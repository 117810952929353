@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Monday-routines';
  src: url('/fonts/Monday_Routines.ttf') format('truetype');
}

@layer base {
  html,
  body {
    @apply max-w-[100vw] overflow-x-hidden font-site-base;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  .smooth {
    @apply transition-all duration-200;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .btn {
    @apply btn--base btn--pseudo hover:bg-site-base-light bg-site-base text-white [&:not([disabled])]:focus:bg-site-accent;
  }

  .btn--accent {
    @apply btn--base btn--pseudo hover:bg-site-base-light bg-site-accent-light text-site-text [&:not([disabled])]:focus:bg-site-accent;
  }

  .btn--conversion {
    @apply btn--base btn--pseudo hover:bg-site-base-light bg-site-conversion text-white [&:not([disabled])]:focus:bg-site-accent;
  }

  .btn--alt {
    @apply btn--base btn--pseudo hover:bg-site-base-light bg-site-accent text-white [&:not([disabled])]:focus:bg-site-accent;
  }

  .btn--outline {
    @apply btn--base btn--pseudo hover:bg-site-base-light hover:border-site-base-light bg-transparent border border-white text-white [&:not([disabled])]:focus:bg-site-accent;
  }

  .section {
    @apply py-8 md:py-10 lg:py-14 xl:py-16;
  }

  .content {
    @apply content--base content--pseudo content--paragraphs content--headings;
  }

  .content.black {
    @apply children-p:!text-black;
  }
}

@layer utilities {
  .btn--pseudo {
    @apply transition-colors disabled:opacity-75;
  }

  .btn--base {
    @apply pb-2 pt-[10px] px-9 text-base rounded-full inline-flex font-normal;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .content--pseudo {
    @apply children-a:underline hover:children-a:text-site-accent;
  }

  .content--base {
    @apply last:[&>*]:mb-0 children-b:font-bold children-strong:font-bold;
  }

  .content--paragraphs {
    @apply children-p:opacity-100 children-p:leading-7 children-p:mb-4 children-p:font-light children-p:text-black;
    @apply children-li:font-light children-li:leading-7;
    @apply children-ul:pl-8;
  }

  .content ul > li {
    @apply list-disc;
  }

  .content ol > li {
    @apply list-decimal;
  }

  .content--headings {
    @apply children-headings:mb-4 children-h1:mb-5 children-h1:text-3xl children-h2:text-2xl children-h3:text-xl children-headings:font-bold children-headings:text-site-text;
  }
}

/* WordPress helpers */

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}

/* Progress bar */

#nprogress .bar {
  @apply bg-site-accent;
}

#nprogress .spinner-icon {
  @apply hidden;
}

#nprogress .peg {
  @apply shadow;
}

/* Form helpers */

.form-duuf-submit > button {
  @apply btn--accent;
}

.form-duuf-group {
  @apply mb-5 relative;
}

.form-duuf-loading {
  @apply absolute inset-0 z-10 flex-center bg-white/50;
}

.form-duuf-label {
  @apply block;
}

.form-duuf-input,
.form-duuf-textarea,
.form-duuf-select__control {
  @apply text-site-text min-w-[250px] px-4 py-2;
}

.form-duuf-error {
  @apply text-red-300 h-fit text-sm absolute -top-5 right-0;
}

.form-duuf-file input {
  @apply hidden;
}

.form-duuf-file-button button {
  @apply btn--accent py-1 px-2;
}

/* Custom */
